import consumer from "./consumer"

consumer.subscriptions.create("PostChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    if (data.type === "post_changed") {
      this.reloadPost(data)
    } else if (data.type === "error") {
      alert(data.text)
    } else {
      alert(`Unknown message type received ${data.type}`)
    }
  },

  reloadPost(data) {
    const postSlug = data.post.slug
    const html = data.html

    const selector = `.post-single[data-post-slug='${postSlug}']`;
    const elements = document.querySelectorAll(selector)
    if (elements.length === 0) { return }

    const wrapper = document.querySelectorAll(".main-content")[0]
    wrapper.innerHTML = html
    Prism.highlightAll()
  }
})
